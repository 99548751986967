import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import lazy from "react-lazy-with-preload";
import React, { Suspense, useEffect, useState, useRef } from "react";
import useCurrentPathname from "./hooks/useCurrentPathname";
import { LocalizationProvider, nlNL } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import 'bootstrap/dist/css/bootstrap.min.css';


const HomePage = lazy(() => import("./pages/HomePage"));


global.SERVER_HOST = process.env.REACT_APP_SERVER_HOST

// global.SCRAP_SERVER_HOST = global.DEV
//   ? "http://localhost:4000"
//   : "https://scrapper.tokbackup.com";


global.SCRAP_SERVER_HOST = process.env.REACT_APP_SCRAPING_SERVER_HOST;


function App() {

  const currentPathname = useCurrentPathname();

  // useEffect(() => {
  //   const token = localStorage.getItem("app-access-token");
  //   if (token && token !== undefined && token !== null) {
  //     console.log(typeof token);
  //     console.log(token);
  //     dispatch(authenticateAsyncThunk());
  //   }
  // }, [dispatch]);

  const PrivateRoute = ({ element: Element }) => {
    const token = localStorage.getItem("app-access-token");
    return token ? (
      <Element />
    ) : (
      <Navigate to="/login" replace state={{ from: "/" }} />
    );
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="App ">
          <BrowserRouter>
            <Suspense
              fallback={
                <div
                  style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%,-50%)",
                    zIndex: "10",
                  }}
                  className="loadingio-spinner-interwind-flyom1cz6sv"
                >
                  <div className="ldio-zxrz71mlja">
                    <div>
                      <div>
                        <div>
                          <div></div>
                        </div>
                      </div>
                      <div>
                        <div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            >
              <div className="pages">
                <Routes>
                  <Route exact path="/" element={<HomePage />} />
                </Routes>
              </div>
            </Suspense>
          </BrowserRouter>
        </div>
      </LocalizationProvider>
    </>
  );
}

export default App;
